/*-----------------------------------------------------------------------------------

    Theme Name: Canvas
    Theme URI: http://themes.semicolonweb.com/html/canvas
    Description: The Multi-Purpose Template
    Author: SemiColonWeb
    Author URI: http://themeforest.net/user/semicolonweb
    Version: 4.5.1

    LESS Stylesheet

-----------------------------------------------------------------------------------*/

@import url('https://fonts.googleapis.com/css?family=Open+Sans');
@import url('https://fonts.googleapis.com/css?family=Libre+Baskerville');

// Bootstrap
@import "bootstrap";

// Initialize
@import "sass/variables.scss";
@import "sass/mixins.scss";


// Core CSS
@import "sass/typography.scss";
@import "sass/helpers.scss";
@import "sass/layouts.scss";


// Content Blocks
@import "sass/topbar.scss";
@import "sass/header.scss";
@import "sass/sliders.scss";
@import "sass/pagetitle.scss";
@import "sass/content.scss";
@import "sass/portfolio.scss";
@import "sass/blog.scss";
@import "sass/shop.scss";
@import "sass/events.scss";


// Shortcodes
@import "sass/shortcodes.scss";


// Footer
@import "sass/footer.scss";


// Widgets
@import "sass/widgets.scss";


// Extras
@import "sass/extras.scss";

// Dark
@import "sass/dark.scss";

// Responsive
@import "sass/responsive.scss";


//CSS Plugins
@import 'css/animate';
@import 'css/font-icons';
@import 'css/spinners';
@import 'css/swiper';
@import '../vendor/featherlight/featherlight.min.css';
@import 'css/select-boxes';
@import 'css/radio-checkbox';

// Site Custom
@import "front";