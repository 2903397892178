

#header-mobile {
    position: fixed;
    width: 100%;
    top: 0;
    background-color: #FFF !important;
    z-index: 390;   

    img {
        max-height: 65px;
        padding: 0 5px;
        margin: auto;
    }
}


#header-open-menu {

    height: 65px; 
    width: 55px;    
    background: #ccc; 
    z-index: 390;

    a { 

        line-height: 65px;
        width: 100%;    

        i {
            font-size: 18px;
        }

    }   
}




#header:not(.sticky-header) #logo img {
    filter: brightness(0) invert(1);
}

#header.transparent-header.full-header {

    background: linear-gradient(to bottom, rgba(0,0,0,0.6) 0%,rgba(0,0,0,0) 100%);
    
}

#header:not(.sticky-header) #primary-menu > ul > li > a {
    color: white !important;
}

.btn-danger {
    background-color: #f46e15;
    border-color: #f46e15;
}

.ares.tparrows {
    opacity: 0.3;
}

.ares.tparrows:hover {
    opacity: 0.8;
}

.megamenu-title {
    margin-top: 10px;
    border-top: solid thin lightgray;
}



/*
    HELPERS
 */

.title-bottom-border {
    border-bottom: solid 1px #eee;
}

.bgcolor-gray {
    background-color: #aaaaaa;
}

.bgcolor-bluegray {
    background-color: #6b9dbb;
    color: white;
}

.rounded-left {
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
}


/*
    PORTADA
 */

.title-hero {
    padding: 165px 20px;
}

.title-hero h1, .title-hero h2 {
    margin: 0;
    padding: 0;
    font-family: 'Libre Baskerville', serif;
}


/* SECCIÓN CONTACTO
    .contacto-tel
 */

.contacto-tel {
    margin-bottom: 25px;
    border-bottom: solid thin #eee;
    padding-bottom: 10px;
}

.contacto-tel h5 {
    font-family: "Arial", sans-serif;
    font-size: 24px;
    color: #f46e15;
    margin: 0;
    padding: 0;
}

.contacto-mail h5 {
    font-family: "Arial", sans-serif;
    font-size: 18px;
    color: #f46e15;
    margin: 0;
    padding: 0;
}

.contacto-tel span {
    margin: 0;
    padding: 0;
}


/*
* SECCIÓN SINGLE PRODUCT
*/

.single-product .product-image {
    border-bottom: solid 1px #F46E15;
}


button.selectable {
    margin: 3px;
}

ul.features {
    background-color: #eee;
}

ul.features li {
    background-color: #eee;
    padding: 5px;
    border: solid 2px white;
}

/*
  SECCIÓN MULTIPLE
 */


.border-box {
    height: auto;
    width: 100%;
    border: 1px solid #aaaaaa;
    position: relative;
}

.border-box .box-label {
    position: absolute;
    top: -10px;
    left: 20px;
    height: 25px;
    width: 50%;
    background-color: #dddddd;
    border: 1px solid #aaaaaa;
    text-align: center;
    color: #f46e15;
}

.border-box .box-content {
    padding: 30px;
}

.border-box .box-content button {
    margin: 2px;
}

div.product {
    min-height: 420px;
}

.btn-default:active:hover, .btn-default.active:hover, .open > .dropdown-toggle.btn-default:hover, .btn-default:active:focus, .btn-default.active:focus, .open > .dropdown-toggle.btn-default:focus, .btn-default:active.focus, .btn-default.active.focus, .open > .dropdown-toggle.btn-default.focus {
    color: #333333;
    background-color: #f46e15;
    border-color: #8c8c8c;
}