
/*-----------------------------------------------------------------------------------

	Shortcodes: social-icons.scss

-----------------------------------------------------------------------------------*/

/* ----------------------------------------------------------------
	Social Icons
-----------------------------------------------------------------*/


.social-icon {
	margin: 0 5px 5px 0;
	width: 40px;
	height: 40px;
	font-size: 20px;
	line-height: 38px !important;
	color: #555;
	text-shadow: none;
	border: 1px solid #AAA;
	@include border-radius(3px);
	overflow: hidden;
}


/* Social Icons - Rounded
-----------------------------------------------------------------*/

.si-rounded { @include border-radius(50%); }

/* Social Icons - Borderless
-----------------------------------------------------------------*/

.si-borderless { border-color: transparent !important; }

/* Social Icons - Dark
-----------------------------------------------------------------*/

.si-dark {
	background-color: #444;
	color: #FFF !important;
	border-color: transparent;
}

/* Social Icons - Light
-----------------------------------------------------------------*/

.si-light {
	background-color: rgba(0,0,0,0.05);
	color: #666 !important;
	border-color: transparent;
}

/* Social Icons - Colored
-----------------------------------------------------------------*/

.si-colored {
	border-color: transparent !important;
	i {
		color: #FFF;
	}
}

/* Social Icons - Large
-----------------------------------------------------------------*/

.social-icon {
	&.si-large {
		margin: 0 10px 10px 0;
		width: 56px;
		height: 56px;
		font-size: 30px;
		line-height: 54px !important;
	}
}

/* Social Icons - Small
-----------------------------------------------------------------*/

.social-icon {
	&.si-small {
		width: 32px;
		height: 32px;
		font-size: 14px;
		line-height: 30px !important;
	}
	i {
		display: block;
		position: relative;
	}
}

body:not(.device-touch) {
	.social-icon {
		i {
			@include transition(all .3s ease);
		}
	}
}

.social-icon {
	i {
		&:last-child {
			color: #FFF !important;
		}
	}
	&:hover {
		color: #555;
		border-color: transparent;
		i {
			&:first-child {
				margin-top: -38px;
			}
		}
	}
	&.si-large {
		&:hover {
			i {
				&:first-child {
					margin-top: -54px;
				}
			}
		}
	}
	&.si-small {
		&:hover {
			i {
				&:first-child {
					margin-top: -30px;
				}
			}
		}
	}
}
.si-colored {
	&.social-icon {
		border-color: transparent;
	}
}

/* Social Icons - Sticky
-----------------------------------------------------------------*/

.si-sticky {
	position: fixed;
	top: 50%;
	left: 5px;
	width: 36px;
	z-index: 100;
}

/* Social Icons - Sticky Right
-----------------------------------------------------------------*/

.si-sticky {
	&.si-sticky-right {
		left: auto;
		right: 8px;
	}
}

/* Social Icons - Share
-----------------------------------------------------------------*/

.si-share {
	position: relative;
	padding-left: 5px;
	border-top: 1px solid #EEE;
	border-bottom: 1px solid #EEE;
	span {
		line-height: 36px;
		font-weight: bold;
		font-size: 13px;
	}
	div {
		float: right;
	}
	.social-icon {
		margin: -1px 0;
		@include border-radius(0);
		font-size: 15px;
	}
}

.si-facebook:hover,
.si-colored.si-facebook { background-color: $facebook !important; }
.si-delicious:hover,
.si-colored.si-delicious { background-color: $delicious !important; }
.si-paypal:hover,
.si-colored.si-paypal { background-color: $paypal !important; }
.si-flattr:hover,
.si-colored.si-flattr { background-color: $flattr !important; }
.si-android:hover,
.si-colored.si-android { background-color: $android !important; }
.si-smashmag:hover,
.si-colored.si-smashmag { background-color: $smashmag !important; }
.si-gplus:hover,
.si-colored.si-gplus { background-color: $gplus !important; }
.si-wikipedia:hover,
.si-colored.si-wikipedia { background-color: $wikipedia !important; }
.si-stumbleupon:hover,
.si-colored.si-stumbleupon { background-color: $stumbleupon !important; }
.si-foursquare:hover,
.si-colored.si-foursquare { background-color: $foursquare !important; }
.si-call:hover,
.si-colored.si-call { background-color: $call !important; }
.si-ninetyninedesigns:hover,
.si-colored.si-ninetyninedesigns { background-color: $ninetyninedesigns !important; }
.si-forrst:hover,
.si-colored.si-forrst { background-color: $forrst !important; }
.si-digg:hover,
.si-colored.si-digg { background-color: $digg !important; }
.si-spotify:hover,
.si-colored.si-spotify { background-color: $spotify !important; }
.si-reddit:hover,
.si-colored.si-reddit { background-color: $reddit !important; }
.si-blogger:hover,
.si-colored.si-blogger { background-color: $blogger !important; }
.si-cc:hover,
.si-colored.si-cc { background-color: $cc !important; }
.si-dribbble:hover,
.si-colored.si-dribbble { background-color: $dribbble !important; }
.si-evernote:hover,
.si-colored.si-evernote { background-color: $evernote !important; }
.si-flickr:hover,
.si-colored.si-flickr { background-color: $flickr !important; }
.si-google:hover,
.si-colored.si-google { background-color: $google !important; }
.si-instapaper:hover,
.si-colored.si-instapaper { background-color: $instapaper !important; }
.si-klout:hover,
.si-colored.si-klout { background-color: $klout !important; }
.si-linkedin:hover,
.si-colored.si-linkedin { background-color: $linkedin !important; }
.si-vk:hover,
.si-colored.si-vk { background-color: $vk !important; }
.si-rss:hover,
.si-colored.si-rss { background-color: $rss !important; }
.si-skype:hover,
.si-colored.si-skype { background-color: $skype !important; }
.si-twitter:hover,
.si-colored.si-twitter { background-color: $twitter !important; }
.si-youtube:hover,
.si-colored.si-youtube { background-color: $youtube !important; }
.si-vimeo:hover,
.si-colored.si-vimeo { background-color: $vimeo !important; }
.si-aim:hover,
.si-colored.si-aim { background-color: $aim !important; }
.si-yahoo:hover,
.si-colored.si-yahoo { background-color: $yahoo !important; }
.si-email3:hover,
.si-colored.si-email3 { background-color: $email3 !important; }
.si-macstore:hover,
.si-colored.si-macstore { background-color: $macstore !important; }
.si-myspace:hover,
.si-colored.si-myspace { background-color: $myspace !important; }
.si-podcast:hover,
.si-colored.si-podcast { background-color: $podcast !important; }
.si-cloudapp:hover,
.si-colored.si-cloudapp { background-color: $cloudapp !important; }
.si-dropbox:hover,
.si-colored.si-dropbox { background-color: $dropbox !important; }
.si-ebay:hover,
.si-colored.si-ebay { background-color: $ebay !important; }
.si-github:hover,
.si-colored.si-github { background-color: $github !important; }
.si-googleplay:hover,
.si-colored.si-googleplay { background-color: $googleplay !important; }
.si-itunes:hover,
.si-colored.si-itunes { background-color: $itunes !important; }
.si-plurk:hover,
.si-colored.si-plurk { background-color: $plurk !important; }
.si-pinboard:hover,
.si-colored.si-pinboard { background-color: $pinboard !important; }
.si-soundcloud:hover,
.si-colored.si-soundcloud { background-color: $soundcloud !important; }
.si-tumblr:hover,
.si-colored.si-tumblr { background-color: $tumblr !important; }
.si-wordpress:hover,
.si-colored.si-wordpress { background-color: $wordpress !important; }
.si-yelp:hover,
.si-colored.si-yelp { background-color: $yelp !important; }
.si-intensedebate:hover,
.si-colored.si-intensedebate { background-color: $intensedebate !important; }
.si-eventbrite:hover,
.si-colored.si-eventbrite { background-color: $eventbrite !important; }
.si-scribd:hover,
.si-colored.si-scribd { background-color: $scribd !important; }
.si-stripe:hover,
.si-colored.si-stripe { background-color: $stripe !important; }
.si-print:hover,
.si-colored.si-print { background-color: $print !important; }
.si-dwolla:hover,
.si-colored.si-dwolla { background-color: $dwolla !important; }
.si-statusnet:hover,
.si-colored.si-statusnet { background-color: $statusnet !important; }
.si-acrobat:hover,
.si-colored.si-acrobat { background-color: $acrobat !important; }
.si-drupal:hover,
.si-colored.si-drupal { background-color: $drupal !important; }
.si-buffer:hover,
.si-colored.si-buffer { background-color: $buffer !important; }
.si-pocket:hover,
.si-colored.si-pocket { background-color: $pocket !important; }
.si-bitbucket:hover,
.si-colored.si-bitbucket { background-color: $bitbucket !important; }
.si-stackoverflow:hover,
.si-colored.si-stackoverflow { background-color: $stackoverflow !important; }
.si-hackernews:hover,
.si-colored.si-hackernews { background-color: $hackernews !important; }
.si-xing:hover,
.si-colored.si-xing { background-color: $xing !important; }
.si-instagram:hover,
.si-colored.si-instagram { background-color: $instagram !important; }
.si-quora:hover,
.si-colored.si-quora { background-color: $quora !important; }
.si-openid:hover,
.si-colored.si-openid { background-color: $openid !important; }
.si-steam:hover,
.si-colored.si-steam { background-color: $steam !important; }
.si-amazon:hover,
.si-colored.si-amazon { background-color: $amazon !important; }
.si-disqus:hover,
.si-colored.si-disqus { background-color: $disqus !important; }
.si-plancast:hover,
.si-colored.si-plancast { background-color: $plancast !important; }
.si-appstore:hover,
.si-colored.si-appstore { background-color: $appstore !important; }
.si-pinterest:hover,
.si-colored.si-pinterest { background-color: $pinterest !important; }
.si-fivehundredpx:hover,
.si-colored.si-fivehundredpx { background-color: $fivehundredpx !important; }


/* Social Icons Text Color
-----------------------------------------------------------------*/

.si-text-color.si-facebook i { color: $facebook; }
.si-text-color.si-delicious i { color: $delicious; }
.si-text-color.si-paypal i { color: $paypal; }
.si-text-color.si-flattr i { color: $flattr; }
.si-text-color.si-android i { color: $android; }
.si-text-color.si-smashmag i { color: $smashmag; }
.si-text-color.si-gplus i { color: $gplus; }
.si-text-color.si-wikipedia i { color: $wikipedia; }
.si-text-color.si-stumbleupon i { color: $stumbleupon; }
.si-text-color.si-foursquare i { color: $foursquare; }
.si-text-color.si-call i { color: $call; }
.si-text-color.si-ninetyninedesigns i { color: $ninetyninedesigns; }
.si-text-color.si-forrst i { color: $forrst; }
.si-text-color.si-digg i { color: $digg; }
.si-text-color.si-spotify i { color: $spotify; }
.si-text-color.si-reddit i { color: $reddit; }
.si-text-color.si-blogger i { color: $blogger; }
.si-text-color.si-cc i { color: $cc; }
.si-text-color.si-dribbble i { color: $dribbble; }
.si-text-color.si-evernote i { color: $evernote; }
.si-text-color.si-flickr i { color: $flickr; }
.si-text-color.si-google i { color: $google; }
.si-text-color.si-instapaper i { color: $instapaper; }
.si-text-color.si-klout i { color: $klout; }
.si-text-color.si-linkedin i { color: $linkedin; }
.si-text-color.si-vk i { color: $vk; }
.si-text-color.si-rss i { color: $rss; }
.si-text-color.si-skype i { color: $skype; }
.si-text-color.si-twitter i { color: $twitter; }
.si-text-color.si-youtube i { color: $youtube; }
.si-text-color.si-vimeo i { color: $vimeo; }
.si-text-color.si-aim i { color: $aim; }
.si-text-color.si-yahoo i { color: $yahoo; }
.si-text-color.si-email3 i { color: $email3; }
.si-text-color.si-macstore i { color: $macstore; }
.si-text-color.si-myspace i { color: $myspace; }
.si-text-color.si-podcast i { color: $podcast; }
.si-text-color.si-cloudapp i { color: $cloudapp; }
.si-text-color.si-dropbox i { color: $dropbox; }
.si-text-color.si-ebay i { color: $ebay; }
.si-text-color.si-github i { color: $github; }
.si-text-color.si-googleplay i { color: $googleplay; }
.si-text-color.si-itunes i { color: $itunes; }
.si-text-color.si-plurk i { color: $plurk; }
.si-text-color.si-pinboard i { color: $pinboard; }
.si-text-color.si-soundcloud i { color: $soundcloud; }
.si-text-color.si-tumblr i { color: $tumblr; }
.si-text-color.si-wordpress i { color: $wordpress; }
.si-text-color.si-yelp i { color: $yelp; }
.si-text-color.si-intensedebate i { color: $intensedebate; }
.si-text-color.si-eventbrite i { color: $eventbrite; }
.si-text-color.si-scribd i { color: $scribd; }
.si-text-color.si-stripe i { color: $stripe; }
.si-text-color.si-print i { color: $print; }
.si-text-color.si-dwolla i { color: $dwolla; }
.si-text-color.si-statusnet i { color: $statusnet; }
.si-text-color.si-acrobat i { color: $acrobat; }
.si-text-color.si-drupal i { color: $drupal; }
.si-text-color.si-buffer i { color: $buffer; }
.si-text-color.si-pocket i { color: $pocket; }
.si-text-color.si-bitbucket i { color: $bitbucket; }
.si-text-color.si-stackoverflow i { color: $stackoverflow; }
.si-text-color.si-hackernews i { color: $hackernews; }
.si-text-color.si-xing i { color: $xing; }
.si-text-color.si-instagram i { color: $instagram; }
.si-text-color.si-quora i { color: $quora; }
.si-text-color.si-openid i { color: $openid; }
.si-text-color.si-steam i { color: $steam; }
.si-text-color.si-amazon i { color: $amazon; }
.si-text-color.si-disqus i { color: $disqus; }
.si-text-color.si-plancast i { color: $plancast; }
.si-text-color.si-appstore i { color: $appstore; }
.si-text-color.si-pinterest i { color: $pinterest; }
.si-text-color.si-fivehundredpx i { color: $fivehundredpx; }

.si-text-color:hover { color: $si_text_color; }